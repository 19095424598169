import { Entry, User } from '../../types'
import { UserMembership } from '../../types/UserMembership'
import { apiClient } from './ApiClient'

export const getCurrentUser: () => Promise<User> = async () => {
  const response = await apiClient.get<User>('/users/current')
  return response.data
}

interface GetUsersParams {
  role?: User['role']
  searchTerm?: string | null
  userMembershipStatus?: 'all' | UserMembership['status']
  membershipId?: string
  page: number
}

export const getUsers: (
  params?: GetUsersParams
) => Promise<{ users: User[]; total: number }> = async (params) => {
  const response = await apiClient.get<{ users: User[]; total: number }>(
    '/users',
    { params }
  )
  return response.data
}

export const getUser: (id: string) => Promise<User> = async (id) => {
  const response = await apiClient.get<User>(`/users/${id}`)
  return response.data
}

export const create: (data: {
  user: Omit<User, 'id' | 'name' | 'pin' | 'company'>
}) => Promise<User> = async (data) => {
  const response = await apiClient.post<User>('/users', data)
  return response.data
}

export const update: (
  id: string,
  data: {
    user: Omit<User, 'id' | 'name' | 'company' | 'dob'> & { dob?: string }
  }
) => Promise<User> = async (id, data) => {
  const response = await apiClient.put<User>(`/users/${id}`, data)
  return response.data
}

interface DestroyUserResponse {
  message: string
}

export const destroy = async (id: string) => {
  const response = await apiClient.delete<DestroyUserResponse>(`/users/${id}`)
  return response.data
}

export const sendInvite: (id: string) => Promise<User> = async (id) => {
  const response = await apiClient.post<User>(`/users/${id}/invite`, {})
  return response.data
}

export const createUserMembership: (
  id: string,
  data: { userMembership: Omit<UserMembership, 'membership' | 'status' | 'id'> }
) => Promise<User> = async (id, data) => {
  const response = await apiClient.post<User>(
    `/users/${id}/user-membership`,
    data
  )
  return response.data
}

export const updateUserMembership: (
  id: string,
  data: { userMembership: Omit<UserMembership, 'membership' | 'status' | 'id'> }
) => Promise<User> = async (id, data) => {
  const response = await apiClient.put<User>(
    `/users/${id}/user-membership`,
    data
  )
  return response.data
}

export const getUserEntries = async (id: string) => {
  const response = await apiClient.get<Entry[]>(`/users/${id}/entries`)
  return response.data
}

export const getNewUsers = async () => {
  const response = await apiClient.get<User[]>(`/users/new`)
  return response.data
}

export const updateUserPlans: (
  id: string,
  data: { plans: { id: string }[] }
) => Promise<User> = async (id, data) => {
  const response = await apiClient.put<User>(`/users/${id}/plans`, data)
  return response.data
}

export const exportUsers = async (params: Omit<GetUsersParams, 'page'> & {type: string}) => {
  const response = await apiClient.get(`/users/export`, { params })
  return response.data
}
